import { useUiStateStore } from "@/@core/services/uiStateStore"
import router from "@/router"
import { reactive, toRefs } from "@vue/composition-api"
import { useAuthStore } from "../../services/store"

export default function useLoginPageModel() {
    const state = reactive({
        isLoading: false,
    })

    const authStore = useAuthStore()

    async function onSignIn(payload: any) {
        state.isLoading = true

        try {
            const isSignedIn = await authStore.signIn(payload)
            if (isSignedIn) {
                router.push('/app')
                useUiStateStore().createSnackbar({ message: 'Berhasil login' })
            }
        } catch (err: any) {
            // useErrorMessageHandler(err)
        } finally {
            state.isLoading = false
        }

    }

    return {
        ...toRefs(state),
        store: authStore,
        onSignIn,
    }
}

export {
    useLoginPageModel
}
