
































import KWrapper from '@/@core/components/other/KWrapper.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KText from '@/@core/components/typography/KText.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import { defineComponent } from '@vue/composition-api';
import AuthLayout from '../../ui/AuthLayout.vue';
import LoginForm from '../../ui/LoginForm.vue';
import useLoginPageModel from './model';

export default defineComponent({
  components: {
    KPage,
    KPageBody,
    KWrapper,
    KText,
    AuthLayout,
    LoginForm
  },
  name: 'AuthLoginPage',
  setup() {
    return {
      isMobile,
      ...useLoginPageModel()
    };
  }
});
